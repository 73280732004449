<template>
  <section>
    <div class="row">
      <div class="col-sm-3">
        <div class="h4">
          <span
            class="clicable"
            v-if="enabled !== undefined"
            @click.stop.prevent="$emit(enabled ? 'disable' : 'enable')"
          >
            <i :class="enabled ? 'fa fa-check-square-o' : 'fa fa-square-o'"></i>
            {{ title || $t("groups") }}
            <Tooltip :title="$t('hints.notification_enabled', 1)" />
          </span>
          <span v-else>
            {{ title || $t("groups") }}
          </span>
        </div>
        <template v-if="enabled === undefined || enabled">
          <draggable
            class="list-group"
            draggable=".item"
            handle=".handle"
            v-bind:list="groups"
            v-on:change="drag"
          >
            <div slot="header" class role="group" aria-label>
              <form role="form" v-if="allowNewGroup">
                <div class="form-group">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="new_group"
                      v-model="new_group"
                      ref="new_group"
                      placeholder="Novo"
                      v-on:keydown.enter.prevent="addGroup"
                    />
                    <div
                      class="input-group-addon btn"
                      v-if="editable_group_index >= 0"
                      title="change"
                      v-on:click="changeGroup"
                    >
                      <i class="fa fa-check"></i>
                    </div>
                    <div
                      class="input-group-addon btn"
                      v-else
                      title="add"
                      v-bind:class="{disabled: !new_group}"
                      v-on:click="addGroup"
                    >
                      <i class="fa fa-plus"></i>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="list-group-item item group-item"
              v-for="(group, index) in groups"
              :data-testid="
                `${$utils
                  .removeDiacritics(group.name.toLowerCase())
                  .replace(/\s/g, '-')}-group`
              "
              v-bind:key="index"
              v-bind:class="{
                active: index == selected_group_index
              }"
              v-on:click.prevent.stop="selectGroup(index)"
            >
              <b>
                <i class="glyphicon glyphicon-option-vertical move handle"></i>
              </b>
              <span>
                <span v-if="displayOrder">{{ index + 1 }} -</span>
                <span v-on:click.prevent.stop="selectGroup(index)">{{
                  group.name
                }}</span>
              </span>
              <span
                v-if="allowGroupRemoval"
                class="badge badge-button"
                v-on:click.prevent.stop="delGroup(index)"
              >
                <i class="fa fa-trash"></i>
              </span>
            </div>
          </draggable>
        </template>
      </div>
      <div class="col-sm-9" v-if="enabled === undefined || enabled">
        <div class="h4" v-if="selectedGroup && selectedGroup.tag_input_label">
          {{ $t(selectedGroup.tag_input_label) }}
          <Tooltip
            :title="$t('hints.notification', {group: selectedGroup.channel})"
          />
        </div>
        <div class="h4" v-else>
          {{ $t("users_to_notify") }}:
          <span>
            <span class="text-primary">{{ selectedGroupName }}</span>
          </span>
          <Tooltip
            :title="$t('hints.notification', {group: selectedGroupName})"
          />
        </div>
        <form role="form">
          <div class="form-group">
            <div class="tag-input">
              <vue-tags-input
                class="tags-master"
                v-if="selectedGroup"
                data-testid="notification"
                v-model="tag"
                v-bind:placeholder="selectedGroup.placeholder || 'email'"
                v-bind:tags="tags"
                v-bind:validation="tag_validation"
                v-bind:allow-edit-tags="true"
                v-bind:add-from-paste="false"
                v-bind:add-on-key="[13, ':', ';']"
                v-on:tags-changed="(newTags) => (tags = newTags)"
              />
              <div v-else disabled class="alert alert-default">
                <p v-if="groups.length">{{ $t("select_a_group") }}</p>
                <p v-else>{{ $t("you_dont_have_any_group_yet") }}</p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import VueTagsInput from "@johmun/vue-tags-input";
import Tooltip from "@/components/tooltip.vue";

function defaults() {
  return {
    group: {
      name: "",
      tag_input_label: "",
      fixed: false,
      tags: [],
      tag_validation: null,
      placeholder: ""
    }
  };
}

export default {
  name: "NotificationUserInputForm",
  components: {
    draggable,
    VueTagsInput,
    Tooltip
  },
  props: {
    title: {
      type: String,
      default: "",
      required: false
    },
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    displayOrder: {
      type: Boolean,
      required: false,
      default: () => false
    },
    allowNewGroup: {
      type: Boolean,
      required: false,
      default: () => true
    },
    allowGroupRemoval: {
      type: Boolean,
      required: false,
      default: () => true
    },
    showGroupSelection: {
      type: Boolean,
      required: false,
      default: () => true
    },
    defaultTagType: {
      // get from the group or from default
      type: String,
      required: false,
      default: () => "email" // "email, number, phone
    },
    enabled: {
      type: Boolean,
      required: false,
      default: undefined
    }
  },
  data: function() {
    return {
      new_group: "",
      editable_group_index: -1,
      selected_group_index: -1,
      tag: "",
      groups: [],
      initializing: false
    };
  },
  computed: {
    tag_validation() {
      if (!this.tags || !this.tags.length) {
        return [];
      }
      var lst = [
        {
          classes: "min-length",
          rule: (tag) => tag.text.length < 7 // a@nn.cc
        },
        {
          classes: "avoid-item",
          rule: /^(?!Cannot).*$/,
          disableAdd: true
        },
        {
          classes: "no-braces",
          rule: ({text}) => text.indexOf("{") !== -1 || text.indexOf("}") !== -1
        }
      ];
      // if there is a tag_validation defined at group level use it
      if (
        this.selectedGroup.tag_validation &&
        this.selectedGroup.tag_validation.rule &&
        this.selectedGroup.tag_validation.classes
      ) {
        var re_string = this.selectedGroup.tag_validation.rule;
        var re = new RegExp(re_string);
        lst.push({
          classes: this.selectedGroup.tag_validation.classes,
          rule: re
        });
      } else {
        // if no group tag_validation try to use the default one (if possible)
        // email
        if (this.defaultTagType.indexOf("email") >= 0) {
          lst.push({
            classes: "no-email",
            rule: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
          });
        }
        // number
        else if (this.defaultTagType.indexOf("number") >= 0) {
          lst.push({
            classes: "no-numbers",
            rule: /^([^0-9]*)$/
          });
        }
        // phone number
        else if (this.defaultTagType.indexOf("phone") >= 0) {
          lst.push({
            classes: "no-numbers",
            rule: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
          });
        }
      }
      return lst;
    },
    selectedGroup: function() {
      return this.selected_group_index >= 0 &&
        this.selected_group_index < this.groups.length
        ? this.groups[this.selected_group_index]
        : null;
    },
    selectedGroupName: function() {
      return (
        (this.selectedGroup && this.selectedGroup.name.toUpperCase()) ||
        this.$t("not_selected")
      );
    },
    tags: {
      get: function() {
        return (this.selectedGroup && this.selectedGroup.tags) || [];
      },
      set: function(newValue) {
        if (this.selectedGroup) {
          this.$set(this.selectedGroup, "tags", newValue);
        }
      }
    }
  },
  watch: {
    groups: {
      handler: function(n, o) {
        let groups = JSON.parse(JSON.stringify(n));
        if (groups && groups.length) {
          groups = groups.map(function(group) {
            group.tags = group.tags.map(function(tag) {
              return tag.text;
            });
            return group;
          });
        }
        this.$emit("input", groups);
      },
      deep: true
    }
  },
  methods: {
    drag: function(ev) {
      if ("moved" in ev) {
        this.new_group = "";
        this.editable_group_index = -1;
        this.selected_group_index = ev.moved.newIndex;
      }
    },
    groupFocus() {
      if (this.$refs.new_group) {
        this.$refs.new_group.focus();
      }
    },
    tagsFocus() {
      //$(".ti-new-tag-input").focus();
    },
    addGroup: function() {
      if (this.new_group && this.allowNewGroup) {
        let group = defaults().group;
        group.name = this.new_group;
        this.groups.push(group);
        this.new_group = "";
        this.selected_group_index = this.groups.length - 1;
        this.groupFocus();
      }
    },
    delGroup: function(index) {
      if (this.allowGroupRemoval) {
        this.groups.splice(index, 1);
        if (index < this.selected_group_index) {
          this.selected_group_index = this.selected_group_index - 1;
        } else if (index == this.selected_group_index) {
          this.selected_group_index =
            index - 1 < 0 && this.groups.length ? 0 : index - 1;
        }
        this.groupFocus();
      }
    },
    selectGroup: function(index) {
      let self = this;
      self.$set(self, "selected_group_index", index);
      self.editable_group_index =
        self.editable_group_index == index ? -1 : index;
      self.new_group =
        self.editable_group_index >= 0 ? self.selectedGroupName : "";
      self.$nextTick(function() {
        if (!this.initializing) {
          if (self.editable_group_index >= 0) {
            this.tagsFocus();
          } else {
            this.groupFocus();
          }
        }
      });
    },
    changeGroup: function() {
      if (this.editable_group_index >= 0) {
        this.groups[this.editable_group_index].name = this.new_group;
        this.editable_group_index = -1;
        this.new_group = "";
      }
    },
    initGroups: function() {
      this.initializing = true;
      if (this.value && this.value.length) {
        let groups = JSON.parse(JSON.stringify(this.value));
        groups = groups.map(function(group) {
          group.tags = group.tags.map(function(tag) {
            return {
              text: typeof tag == "object" && tag.text ? tag.text : tag
            };
          });
          return group;
        });
        this.$set(this, "groups", groups);
      }
      if (this.groups.length) {
        this.selectGroup(0);
        this.initializing = false;
      }
    }
  },
  created() {
    this.initGroups();
  }
};
</script>

<style scoped>
.group-item {
  border-left: 2px solid lightgray;
  padding: 10px 5px 10px 8px;
}

.group-item {
  cursor: pointer;
}

.group-item i.move {
  color: gray;
  margin-right: 10px;
  cursor: move;
}

.group-item.active i.move {
  color: white;
}

.badge-button {
  background-color: transparent;
  color: gray;
}

.tag-input {
  border: 1px solid #ccc;
  min-height: 120px;
  border-radius: 3px;
}
</style>

<style lang="css">
.vue-tags-input {
  min-width: 100%;
}

.vue-tags-input .ti-input {
  border: 0 !important;
}

.vue-tags-input.ti-focus .ti-input {
  border: 0 !important;
  background: transparent;
}
</style>
